@import '@/styles/artifacts.scss';




















































.values {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 16px;
  height: 100%;
}
.value {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  border-radius: 38px;
  margin-bottom: 16px;
  width: 76px;
  height: 76px;
  background-color: colorVodafone(vodafone-white);
  text-align: center;
  font-size: fontSize(fs-300);
  line-height: 76px;
}
.temperature {
  font-size: fontSize(fs-300);
  line-height: lineHeight(lh-100);
  color: colorVodafone(vodafone-red);
}
.place {
  font-size: fontSize(fs-base);
  line-height: lineHeight(lh-reset);
  color: colorVodafone(black);
}
